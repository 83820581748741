import { Navbar } from './navbar';
import Footer from './footer';

const PageWrap = ({ page, children }) => {
  return (
    <>
      <Navbar page={page} />
      {children}
      <Footer />
    </>
  );
};

export default PageWrap;
