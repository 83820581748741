import * as React from 'react';
import {
  Box,
  Text,
  SimpleGrid,
  Heading,
  Stack,
  Container,
  useBreakpointValue,
  Link,
  Divider,
} from '@chakra-ui/react';

import { Quote } from '../../components/quote';

const App = () => (
  <>
    <Divider />
    <Box as="section" bg="bg-surface">
      <Container py={16}>
        <Stack spacing={{ base: '4', md: '5' }}>
          <Heading
            size={useBreakpointValue({
              sm: 'sm',
              base: 'md',
            })}
            fontWeight="extrabold"
          >
            Rated highly by all customers!
          </Heading>
          <Text color="muted" fontSize="xl">
            Here's a few of our customers' reviews. Check out our{' '}
            <Link color="accent" href="/testimonials">
              testimonials page
            </Link>{' '}
            to see more.
          </Text>
        </Stack>
        <SimpleGrid
          columns={useBreakpointValue({ base: 1, md: 3 })}
          py={6}
          spacing={8}
        >
          <Quote
            review={`Highly recommend! When other carpet repair
  companies were asked if they could repair our
  ripped carpet they all said it couldn't be done and
  that it would be best to replace the whole 2m x 4m
  bedroom! The Carpet Repair Specialist did a
  miracle work and blended in our carpet seamlessly.
  Reasonably priced, couldn't be more happy.`}
            name={'Sherry Lee'}
          />

          <Quote
            review={`Super, super happy with our carpet restretch
  today. Mark was punctual, easy to contact,
  professional and very knowledgeable regarding the
  restretch and cause of the issue. I highly
  recommend The Carpet Repair Specialist and
  wouldn't hesitate to book his services again.`}
            name="Michelle Martin"
          />
          <Quote
            review={`Highly recommend. From first contact,
  they were informative and friendly. Professional, they
  patched our carpet in no time and did a beautiful
  job. Wouldn't hesitate to use The Carpet Repair
  Specialist in the future.`}
            name={'Gina Carlucci'}
          />
        </SimpleGrid>
      </Container>
    </Box>
  </>
);

export default App;
