import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
const features = [
  {
    name: 'Crain 520 Power Stretching System.',
    description:
      'Gives you the benefits of both straight and swivel heads. The heads have a 60 degree free swivel for patterned carpets. The built in lock pin can be adjusted into 5 convenient positions. Also features a stretch lock button for partial stretches. The head pin depth can be adjusted to accept various carpet thicknesses. Adjusts from 37 inches to 23 feet and can be lengthened with the purchase of additional tubes. The Crain Power Stretcher set has included an Auto Lock Tube, 3 extension tubes, transfer tube and folding tail block.',
  },
  {
    name: 'Crain 515 / 519 Mini-Stretcher with Seam Repair Attachment',
    description:
      'The Mini Stretcher is great for pattern matching, fast restretching without tubes, and seam repair. Features include stretch-lock button, two fully pivotable gripper heads, open top wall blade for fast tucking. Gripper heads and wall blade are quickly connected onto either end using the push-button lock pins. Tall seam repair legs that create a 9" high by 10" wide work area to run an iron beneath are No. 519.',
  },
  {
    name: 'Kool Glide Carpet Seaming System',
    description: `Patented radio-wave technology evenly spreads signal through the surface of the carpet right down to the specially engineered seam tape — tool never touches the adhesive!
      No heat.
      No distortion of carpet fibers — no delamination of backing.
      No Smoke / No Odor!`,
  },
  {
    name: 'Half Moon Steel Collar Needles',
    description:
      "Your style. Your blue. Customize the components as you need them. It's that simple.",
  },
];
const App = () => (
  <Box as="section" bg="bg-surface">
    <Container py={12}>
      <Stack spacing={{ base: '12', md: '16' }}>
        <Stack
          spacing={{
            base: '4',
            md: '6',
          }}
          maxW={{
            md: 'xl',
            lg: 'md',
            xl: 'xl',
          }}
          marginX="auto"
          textAlign="center"
        >
          <Heading size={'md'} fontWeight="extrabold">
            Our equipment
          </Heading>
          <Text
            fontSize={{
              base: 'lg',
              md: 'xl',
            }}
            color="muted"
          >
            We use the best equipment to ensure the best results. We have a wide
            range of tools and equipment to ensure we can repair any carpet. We
            are proud to posess the following equipment:
          </Text>
        </Stack>
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          columnGap={8}
          rowGap={{ base: 10, md: 16 }}
        >
          {features.map(feature => (
            <Stack
              key={feature.name}
              spacing={{ base: '4', md: '5' }}
              textAlign="center"
            >
              <Stack spacing={{ base: '1', md: '2' }} flex="1">
                <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight="medium">
                  {feature.name}
                </Text>
                <Text color="muted">{feature.description}</Text>
              </Stack>
            </Stack>
          ))}
        </SimpleGrid>
      </Stack>
    </Container>
  </Box>
);

export default App;
