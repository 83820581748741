import {
  Badge,
  Box,
  Button,
  Container,
  Heading,
  Img,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import * as React from 'react';
import PageWrap from '../components/page-wrap';
import Quotes from '../sections/home/quotes';
import About from '../sections/home/about';

const App = () => (
  <PageWrap page={'Home'}>
    <Box as="section" bg="bg-surface">
      <Box
        position="relative"
        height={{
          lg: '640px',
        }}
      >
        <Container
          py={{
            base: '16',
            md: '24',
          }}
          height="full"
        >
          <Stack
            direction={{
              base: 'column',
              lg: 'row',
            }}
            spacing={{
              base: '16',
            }}
            align={{
              lg: 'center',
            }}
            height="full"
          >
            <Stack
              spacing={{
                base: '8',
                md: '12',
              }}
            >
              <Stack spacing="4">
                <Badge
                  colorScheme="blue"
                  alignSelf="start"
                  size={useBreakpointValue({
                    base: 'md',
                    md: 'lg',
                  })}
                >
                  Welcome to The Perth Carpet Repair Specialist website!
                </Badge>
                <Stack
                  spacing={{
                    base: '4',
                    md: '6',
                  }}
                  maxW={{
                    md: 'xl',
                    lg: 'md',
                    xl: 'xl',
                  }}
                >
                  <Heading size={'md'} fontWeight="extrabold">
                    Why pay for a brand new carpet installation when you can get
                    it repaired for a fraction of the cost?
                  </Heading>
                  <Text
                    fontSize={{
                      base: 'lg',
                      md: 'xl',
                    }}
                    color="muted"
                  >
                    We combine both traditional sewing & latest modern
                    technology methods to repair your carpets to look brand new.
                  </Text>
                </Stack>
              </Stack>
              <Stack
                direction={{
                  base: 'column',
                  md: 'row',
                }}
                spacing="3"
              >
                <Button
                  variant="primary"
                  as={'a'}
                  href="/quote"
                  size={useBreakpointValue({
                    base: 'lg',
                    md: 'xl',
                  })}
                >
                  Get a quote
                </Button>
                <Button
                  variant="secondary"
                  as={'a'}
                  href="/about"
                  size={useBreakpointValue({
                    base: 'lg',
                    md: 'xl',
                  })}
                >
                  Learn more
                </Button>
              </Stack>
            </Stack>
            <Box
              pos={{
                lg: 'absolute',
              }}
              right="0"
              bottom="0"
              w={{
                base: 'full',
                lg: '50%',
              }}
              height={{
                base: '96',
                lg: 'full',
              }}
              sx={{
                clipPath: {
                  lg: 'polygon(7% 0%, 100% 0%, 100% 100%, 0% 100%)',
                },
              }}
            >
              <Img
                boxSize="full"
                objectFit="cover"
                src="https://scontent-sin6-1.xx.fbcdn.net/v/t39.30808-6/276102893_319390843589702_6158926783970526832_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=e3f864&_nc_ohc=-Y7Vz1Luf7YAX_wbThy&_nc_ht=scontent-sin6-1.xx&oh=00_AT_gV5gcjC012BYPEEofPmcz_MsF0tlOgjhw0FC0z4Inaw&oe=63442B15"
                alt="Picture of carpet"
              />
            </Box>
          </Stack>
        </Container>
      </Box>
    </Box>
    <Quotes />
    <About />
  </PageWrap>
);

export default App;
