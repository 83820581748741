const suburbs = [
  'Abba River',
  'Abbey',
  'Acton Park',
  'Adamsvale',
  'Ajana',
  'Albany',
  'Aldersyde',
  'Alexander Heights',
  'Alexandra Bridge',
  'Alfred Cove',
  'Alkimos',
  'Allanooka',
  'Allanson',
  'Alma',
  'Ambania',
  'Ambergate',
  'Amelup',
  'Angelo River',
  'Anketell',
  'Anniebrook',
  'Antonymyre',
  'Applecross',
  'Applecross North',
  'Ardath',
  'Ardross',
  'Argyle',
  'Armadale',
  'Arrino',
  'Arrowsmith',
  'Arrowsmith East',
  'Arthur River',
  'Ascot',
  'Ashby',
  'Ashendon',
  'Ashfield',
  'Attadale',
  'Atwell',
  'Aubin Grove',
  'Augusta',
  'Australind',
  'Aveley',
  'Avon Valley National Park',
  'Baandee',
  'Babakin',
  'Babbage Island',
  'Badgebup',
  'Badgerin Rock',
  'Badgin',
  'Badgingarra',
  'Badjaling',
  'Bailup',
  'Bakers Hill',
  'Balbarrup',
  'Balcatta',
  'Baldivis',
  'Balga',
  'Balingup',
  'Balkuling',
  'Balla Balla',
  'Balladong',
  'Balladonia',
  'Ballajura',
  'Ballaying',
  'Ballidu',
  'Bally Bally',
  'Bambun',
  'Bandy Creek',
  'Bandya',
  'Banjup',
  'Banksia Grove',
  'Banksiadale',
  'Bannister',
  'Barbalin',
  'Barberton',
  'Barrabup',
  'Barragup',
  'Barrow Island',
  'Baskerville',
  'Bassendean',
  'Bateman',
  'Baudin',
  'Baynton',
  'Bayonet Head',
  'Bayswater',
  'Beachlands',
  'Beacon',
  'Beaconsfield',
  'Beadell',
  'Beaufort River',
  'Beaumont',
  'Beckenham',
  'Bedford',
  'Bedfordale',
  'Beechboro',
  'Beechina',
  'Beedelup',
  'Beela',
  'Beelerup',
  'Beeliar',
  'Beenong',
  'Beermullah',
  'Bejoording',
  'Beldon',
  'Belhus',
  'Bellevue',
  'Belmont',
  'Bencubbin',
  'Benger',
  'Benjaberring',
  'Benjinup',
  'Bennett Springs',
  'Bentley',
  'Bentley South',
  'Beresford',
  'Berkshire Valley',
  'Bernier Island',
  'Bertram',
  'Beverley',
  'Bibra Lake',
  'Bickley',
  'Bicton',
  'Biddelia',
  'Big Grove',
  'Bilbarin',
  'Bilingurr',
  'Bimbijy',
  'Bindi Bindi',
  'Bindoon',
  'Bindoon Training Area',
  'Binduli',
  'Binningup',
  'Binnu',
  'Birchmont',
  'Blythewood',
  'Boallia',
  'Bobalong',
  'Bodallin',
  'Boddington',
  'Boilup',
  'Bokal',
  'Bolgart',
  'Bonnie Rock',
  'Bonniefield',
  'Boodarie',
  'Boodarockin',
  'Bookara',
  'Boolading',
  'Boonanarring',
  'Boorabbin',
  'Booragoon',
  'Booralaming',
  'Boorara',
  'Boorara Brook',
  'Bootenal',
  'Boothendarra',
  'Boraning',
  'Boranup',
  'Borden',
  'Borderdale',
  'Bornholm',
  'Boscabel',
  'Boulder',
  'Boundain',
  'Bouvard',
  'Bovell',
  'Bow Bridge',
  'Bowelling',
  'Bowes',
  'Bowgada',
  'Boxwood Hill',
  'Boya',
  'Boyanup',
  'Boyatup',
  'Boyerine',
  'Boyup Brook',
  'Brabham',
  'Bramley',
  'Brazier',
  'Breera',
  'Bremer Bay',
  'Brentwood',
  'Breton Bay',
  'Bridgetown',
  'Brigadoon',
  'Bringo',
  'Broadwater',
  'Broadway Nedlands',
  'Broadwood',
  'Brockman',
  'Broke',
  'Brookdale',
  'Brookhampton',
  'Brookton',
  'Broome',
  'Broomehill',
  'Broomehill East',
  'Broomehill Village',
  'Broomehill West',
  'Brown Hill',
  'Brown Range',
  'Bruce Rock',
  'Brunswick',
  'Buckingham',
  'Buckland',
  'Bulgarra',
  'Bull Creek',
  'Bullabulling',
  'Bullaring',
  'Buller',
  'Bullfinch',
  'Bullock Hills',
  'Bullsbrook',
  'Bulong',
  'Bulyee',
  'Bunbury',
  'Bundanoon',
  'Bungulla',
  'Buniche',
  'Bunjil',
  'Buntine',
  'Burakin',
  'Buraminya',
  'Burekup',
  'Burges',
  'Burlong',
  'Burma Road',
  'Burns Beach',
  'Burnside',
  'Burracoppin',
  'Burran Rock',
  'Burrup',
  'Burswood',
  'Burtville',
  'Busselton',
  'Butler',
  'Byford',
  'Cable Beach',
  'Cadoux',
  'Caiguna',
  'Calingiri',
  'Calista',
  'Caljie',
  'Callcup',
  'Camballin',
  'Cambridge Gulf',
  'Camillo',
  'Cancanning',
  'Cane',
  'Canna',
  'Canning Bridge Applecross',
  'Canning Mills',
  'Canning Vale',
  'Canning Vale East',
  'Canning Vale South',
  'Cannington',
  'Cape Arid',
  'Cape Burney',
  'Cape Le Grand',
  'Cape Range National Park',
  'Cape Riche',
  'Capel',
  'Capel River',
  'Capricorn',
  'Caraban',
  'Carabooda',
  'Carani',
  'Carbla',
  'Carbunup River',
  'Cardiff',
  'Cardup',
  'Carey Park',
  'Carine',
  'Carlisle',
  'Carlisle North',
  'Carlisle South',
  'Carlotta',
  'Carmel',
  'Carnamah',
  'Carnarvon',
  'Carrabin',
  'Carramar',
  'Carrarang',
  'Carrolup',
  'Cartmeticup',
  'Cascade',
  'Castletown',
  'Casuarina',
  'Cataby',
  'Catterick',
  'Caversham',
  'Centennial Park',
  'Cervantes',
  'Chadwick',
  'Champion Lakes',
  'Chandler',
  'Changerup',
  'Channybearup',
  'Chapman Hill',
  'Charley Creek',
  'Cheynes',
  'Chichester',
  'Chidlow',
  'Chittering',
  'Chowerup',
  'Christmas Island',
  'Churchlands',
  'City Beach',
  'Clackline',
  'Claremont',
  'Claremont North',
  'Clarkson',
  'Cleary',
  'Cleaverville',
  'Clifton',
  'Cloisters Square',
  'Cloverdale',
  'Coblinine',
  'Coburn',
  'Cockatoo Island',
  'Cockburn Central',
  'Cocklebiddy',
  'Codjatotine',
  'Cold Harbour',
  'Collanilling',
  'College Grove',
  'Collie',
  'Collie Burn',
  'Collingwood Heights',
  'Collingwood Park',
  'Collins',
  'Commodine',
  'Como',
  'Condingup',
  'Congelin',
  'Connolly',
  'Contine',
  'Coodanup',
  'Coogee',
  'Cookernup',
  'Cooladar Hill',
  'Coolbellup',
  'Coolbinia',
  'Coolcalalaya',
  'Coolgardie',
  'Cooljarloo',
  'Cooloongup',
  'Coolup',
  'Coomalbidgup',
  'Coomberdale',
  'Coonabidgee',
  'Coondle',
  'Coorow',
  'Copley',
  'Coral Bay',
  'Cordering',
  'Corinthia',
  'Corrigin',
  'Cosmo Newbery',
  'Cossack',
  'Cottesloe',
  'Courtenay',
  'Cowalellup',
  'Cowalla',
  'Cowaramup',
  'Cowcowing',
  'Coyrecup',
  'Craigie',
  'Cramphorne',
  'Cranbrook',
  'Crawley',
  'Crooked Brook',
  'Crossman',
  'Crowea',
  'Cuballing',
  'Cubbine',
  'Cue',
  'Culbin',
  'Culham',
  'Cullacabardee',
  'Cullalla',
  'Cundeelee',
  'Cunderdin',
  'Cundinup',
  'Cunjardine',
  'Currambine',
  'Cuthbert',
  'Daadenning Creek',
  'Daggar Hills',
  'Daglish',
  'Dale',
  'Daliak',
  'Dalkeith',
  'Dalwallinu',
  'Dalyellup',
  'Dalyup',
  'Dampier',
  'Dampier Archipelago',
  'Dampier Peninsula',
  'Dandanning',
  'Dandaragan',
  'Dangin',
  'Darch',
  'Dardadine',
  'Dardanup',
  'Dardanup West',
  'Darkan',
  'Darling Downs',
  'Darlington',
  'Darradup',
  'Dartmoor',
  'Dartnall',
  'Datatine',
  'Davenport',
  'Dawesville',
  'Dayton',
  'De Grey',
  'Deanmill',
  'Deepdale',
  'Deepdene',
  'Denbarker',
  'Denham',
  'Denmark',
  'Derby',
  'Devils Creek',
  'Diamond Tree',
  'Dianella',
  'Dindiloa',
  'Dingup',
  'Dinninup',
  'Dirk Hartog Island',
  'Dixvale',
  'Djugun',
  'Dog Swamp',
  'Dongara',
  'Dongolocking',
  'Donnelly River',
  'Donnybrook',
  'Doodenanning',
  'Doodlakine',
  'Doongin',
  'Dorre Island',
  'Doubleview',
  'Dowerin',
  'Drome',
  'Drummond Cove',
  'Dryandra',
  'Drysdale River',
  'Dudawa',
  'Dudinin',
  'Dudley Park',
  'Dukin',
  'Dulbelling',
  'Dulyalbin',
  'Dumbarton',
  'Dumberning',
  'Dumbleyung',
  'Duncraig',
  'Dundas',
  'Dunn Rock',
  'Dunsborough',
  'Durack',
  'Duranillin',
  'Durawah',
  'Dwarda',
  'Dwellingup',
  'Eagle Bay',
  'East Augusta',
  'East Ballidu',
  'East Beverley',
  'East Bowes',
  'East Bunbury',
  'East Cannington',
  'East Carnarvon',
  'East Chapman',
  'East Damboring',
  'East Fremantle',
  'East Lyons River',
  'East Munglinup',
  'East Murchison',
  'East Nabawa',
  'East Nannup',
  'East Newdegate',
  'East Perth',
  'East Pingelly',
  'East Rockingham',
  'East Victoria Park',
  'East Wickepin',
  'East Yuna',
  'Eastbrook',
  'Eaton',
  'Eden Hill',
  'Edgewater',
  'Eganu',
  'Eglinton',
  'Eighty Mile Beach',
  'Elabbin',
  'Elachbutting',
  'Elgin',
  'Elleker',
  'Ellenbrook',
  'Ellenbrook East',
  'Ellendale',
  'Embleton',
  'Emu Flat',
  'Eneabba',
  'Ennuin',
  'Eradu',
  'Eradu South',
  'Erskine',
  'Esperance',
  'Etmilyn',
  'Eucla',
  'Eurardy',
  'Ewlyamartup',
  'Exmouth',
  'Exmouth Gulf',
  'Fairbridge',
  'Falcon',
  'Ferguson',
  'Ferndale',
  'Feysville',
  'Fimiston',
  'Finucane',
  'Fitzgerald',
  'Fitzgerald River National Park',
  'Fitzroy Crossing',
  'Flint',
  'Floreat',
  'Flynn',
  'Forest Grove',
  'Forest Hill',
  'Forrest',
  'Forrest Beach',
  'Forrestania',
  'Forrestdale',
  'Forrestfield',
  'Fortescue',
  'Francois Peron National Park',
  'Frankland',
  'Frankland River',
  'Fraser Range',
  'Fremantle',
  'Frenchman Bay',
  'Furnissdale',
  'Gabalong',
  'Gabbadah',
  'Gabbin',
  'Gairdner',
  'Gap Ridge',
  'Garden Island',
  'Gascoyne Junction',
  'Gascoyne River',
  'Geegully Creek',
  'Gelorup',
  'Geographe',
  'Georgina',
  'Geraldton',
  'Ghooli',
  'Gibb',
  'Gibson',
  'Gibson Desert North',
  'Gibson Desert South',
  'Gidgegannup',
  'Gilgering',
  'Gillimanning',
  'Gillingarra',
  'Gilroyd',
  'Gingerah',
  'Gingin',
  'Ginginup',
  'Girrawheen',
  'Gledhow',
  'Glen Forrest',
  'Glen Iris',
  'Glen Mervyn',
  'Glencoe',
  'Glendalough',
  'Glenfield',
  'Glenlynn',
  'Glenoran',
  'Glentromie',
  'Gnangara',
  'Gnarabup',
  'Gnoorea',
  'Gnowangerup',
  'Gnowellen',
  'Golden Bay',
  'Goldsworthy',
  'Goode Beach',
  'Goodlands',
  'Goomalling',
  'Goomarin',
  'Gooseberry Hill',
  'Gorge Rock',
  'Gorrie',
  'Gosnells',
  'Gracetown',
  'Granville',
  'Grass Patch',
  'Grass Valley',
  'Green Head',
  'Green Range',
  'Green Valley',
  'Greenbushes',
  'Greenfields',
  'Greenhills',
  'Greenmount',
  'Greenough',
  'Greenwood',
  'Greenwoods Valley',
  'Gregory',
  'Grey',
  'Greys Plain',
  'Grimwade',
  'Guilderton',
  'Guildford',
  'Gundaring',
  'Gunyidi',
  'Gutha',
  'Gwambygine',
  'Gwelup',
  'Gwindinup',
  'Hacketts Gully',
  'Halls Creek',
  'Halls Head',
  'Hamel',
  'Hamelin Bay',
  'Hamersley',
  'Hamersley Range',
  'Hamilton Hill',
  'Hammond Park',
  'Hannans',
  'Harris River',
  'Harrisdale',
  'Harrismith',
  'Harvey',
  'Hastings',
  'Hatter Hill',
  'Hay',
  'Haynes',
  'Hazelmere',
  'Hazelvale',
  'Heathridge',
  'Helena Valley',
  'Henderson',
  'Henley Brook',
  'Henty',
  'Herdsman',
  'Herne Hill',
  'Herron',
  'Hester',
  'Hester Brook',
  'Hickety',
  'Higginsville',
  'High Wycombe',
  'Highbury',
  'Highgate',
  'Hilbert',
  'Hill River',
  'Hillarys',
  'Hillman',
  'Hillman River',
  'Hillside',
  'Hilton',
  'Hindmarsh',
  'Hines Hill',
  'Hithergreen',
  'Hocking',
  'Hoddys Well',
  'Hoffman',
  'Holleton',
  'Holmwood',
  'Holt Rock',
  'Holyoake',
  'Home Island Cocos (Keeling) Islands',
  'Hope Valley',
  'Hopeland',
  'Hopetoun',
  'Horrocks',
  'Houtman Abrolhos',
  'Hovea',
  'Howatharra',
  'Howick',
  'Hulongine',
  'Huntingdale',
  'Hyden',
  'Ikewa',
  'Iluka',
  'Indee',
  'Inggarda',
  'Inglehope',
  'Inglewood',
  'Inkpen',
  'Innaloo',
  'Innawanga',
  'Irishtown',
  'Irwin',
  'Israelite Bay',
  'Isseka',
  'Jackitup',
  'Jacup',
  'Jalbarragup',
  'Jaloran',
  'Jandabup',
  'Jandakot',
  'Jane Brook',
  'Jardee',
  'Jarrahdale',
  'Jarrahwood',
  'Jelcobine',
  'Jennacubbine',
  'Jennapullin',
  'Jerdacuttup',
  'Jerramungup',
  'Jibberding',
  'Jilakin',
  'Jindalee',
  'Jindong',
  'Jingalup',
  'Jitarning',
  'Jolimont',
  'Joondalup',
  'Joondanna',
  'Julimar',
  'Juna Downs',
  'Jurien Bay',
  'Kadathinni',
  'Kalamunda',
  'Kalannie',
  'Kalbarri',
  'Kalbarri National Park',
  'Kalgan',
  'Kalgoorlie',
  'Kalgup',
  'Kallaroo',
  'Kaloorup',
  'Kalumburu',
  'Kambalda',
  'Kambalda East',
  'Kambalda West',
  'Kangaroo Gully',
  'Kanowna',
  'Karakin',
  'Karalundi',
  'Karawara',
  'Kardinya',
  'Karijini',
  'Karlgarin',
  'Karlkurla',
  'Karloning',
  'Karloo',
  'Karnup',
  'Karragullen',
  'Karrakatta',
  'Karrakup',
  'Karramindie',
  'Karranadgin',
  'Karratha',
  'Karratha Industrial Estate',
  'Karridale',
  'Karrinyup',
  'Karroun Hill',
  'Katanning',
  'Katrine',
  'Kauring',
  'Kealy',
  'Kebaringup',
  'Kellerberrin',
  'Kelmscott',
  'Kendenup',
  'Kenmare',
  'Kennedy Range',
  'Kensington',
  'Kentdale',
  'Kenwick',
  'Keralup',
  'Kewdale',
  'Keysbrook',
  'Kiara',
  'Kimbolton',
  'King Leopold Ranges',
  'King River',
  'Kings Park',
  'Kingsford',
  'Kingsley',
  'Kingston',
  'Kingsway',
  'Kinross',
  'Kirk Rock',
  'Kirup',
  'Kiwirrkurra',
  'Kojaneerup South',
  'Kojarena',
  'Kojonup',
  'Kokeby',
  'Kondinin',
  'Kondut',
  'Konnongorring',
  'Koojan',
  'Kookynie',
  'Koolan Island',
  'Koolanooka',
  'Koolyanobbing',
  'Koomberkine',
  'Koondoola',
  'Koongamia',
  'Koorda',
  'Korbel',
  'Kordabup',
  'Korrelocking',
  'Kronkup',
  'Kudardup',
  'Kuender',
  'Kukerin',
  'Kulikup',
  'Kulin',
  'Kulin West',
  'Kulja',
  'Kumarina',
  'Kunjin',
  'Kununoppin',
  'Kununurra',
  'Kurnalpi',
  'Kurrenkutten',
  'Kweda',
  'Kwelkan',
  'Kwinana',
  'Kwinana Beach',
  'Kwinana Town Centre',
  'Kwolyin',
  'Lagrange',
  'Lake Argyle',
  'Lake Austin',
  'Lake Biddy',
  'Lake Brown',
  'Lake Camm',
  'Lake Carnegie',
  'Lake Clifton',
  'Lake Darlot',
  'Lake Deborah',
  'Lake Grace',
  'Lake Hinds',
  'Lake Jasper',
  'Lake King',
  'Lake Margarette',
  'Lake Muir',
  'Lake Ninan',
  'Lake Toolbrunup',
  'Lake Wells',
  'Lakelands',
  'Lakewood',
  'Lamington',
  'Lancelin',
  'Landsdale',
  'Lange',
  'Langford',
  'Latham',
  'Lathlain',
  'Laverton',
  'Learmonth',
  'Leda',
  'Leederville',
  'Leeman',
  'Leeming',
  'Leeuwin',
  'Leinster',
  'Lennard Brook',
  'Leonora',
  'Leschenault',
  'Lesley',
  'Lesmurdie',
  'Lexia',
  'Lime Lake',
  'Linfarne',
  'Little Grove',
  'Little Italy',
  'Little Sandy Desert',
  'Lockier',
  'Lockridge',
  'Lockyer',
  'Lol Gray',
  'Londonderry',
  'Lort River',
  'Lowden',
  'Lower Chittering',
  'Lower Hotham',
  'Lower King',
  'Lowlands',
  'Ludlow',
  'Lumeah',
  'Lyalls Mill',
  'Lyndon',
  'Lynwood',
  'Macleod',
  'Maddington',
  'Madeley',
  'Madora Bay',
  'Madura',
  'Magenta',
  'Magitup',
  'Mahogany Creek',
  'Mahomets Flats',
  'Maida Vale',
  'Maitland',
  'Malabaine',
  'Malaga',
  'Malebelling',
  'Mallee Hill',
  'Malmalling',
  'Malyalling',
  'Mandogalup',
  'Mandurah',
  'Mandurah East',
  'Mandurah North',
  'Manjimup',
  'Manmanning',
  'Manning',
  'Manypeaks',
  'Marangaroo',
  'Maranup',
  'Marbelup',
  'Marble Bar',
  'Marchagee',
  'Mardella',
  'Mardie',
  'Margaret River',
  'Mariginiup',
  'Marmion',
  'Marne',
  'Marracoonda',
  'Marradong',
  'Marrah',
  'Marrinup',
  'Martin',
  'Marvel Loch',
  'Marybrook',
  'Massey Bay',
  'Mauds Landing',
  'Mawson',
  'Maya',
  'Mayanup',
  'Maylands',
  'Mcalinden',
  'Mcbeath',
  'Mckail',
  'Meadow',
  'Meadow Springs',
  'Meckering',
  'Meda',
  'Medina',
  'Meekatharra',
  'Meeking',
  'Meelon',
  'Meenaar',
  'Meerup',
  'Melaleuca',
  'Melville',
  'Menora',
  'Menzies',
  'Merilup',
  'Merivale',
  'Merkanooka',
  'Merredin',
  'Merriwa',
  'Meru',
  'Metricup',
  'Mettler',
  'Miamoon',
  'Middle Swan',
  'Middlesex',
  'Middleton Beach',
  'Midland',
  'Midvale',
  'Miling',
  'Millars Well',
  'Millbridge',
  'Millbrook',
  'Millendon',
  'Mills Lake',
  'Millstream',
  'Milo',
  'Milpara',
  'Mimegarra',
  'Mindarabin',
  'Mindarie',
  'Mindarra',
  'Minding',
  'Mingenew',
  'Minigin',
  'Minilya',
  'Minnenooka',
  'Minnivale',
  'Minyirr',
  'Mira Mar',
  'Mirrabooka',
  'Mitchell Plateau',
  'Mobrup',
  'Mocardy',
  'Mogumber',
  'Mokine',
  'Mokup',
  'Mollerin',
  'Molloy Island',
  'Monjebup',
  'Monjingup',
  'Monkey Mia',
  'Moodiarrup',
  'Moojebing',
  'Mooliabeenee',
  'Moondah',
  'Moondyne',
  'Moonies Hill',
  'Moonyoonooka',
  'Moora',
  'Moore River National Park',
  'Mooriary',
  'Moorine Rock',
  'Morangup',
  'Morawa',
  'Morbinning',
  'Mordalup',
  'Moresby',
  'Morgantown',
  'Morley',
  'Mornington',
  'Mosman Park',
  'Moulyinning',
  'Mount Adams',
  'Mount Anketell',
  'Mount Barker',
  'Mount Budd',
  'Mount Burges',
  'Mount Caroline',
  'Mount Claremont',
  'Mount Clarence',
  'Mount Cooke',
  'Mount Elphinstone',
  'Mount Erin',
  'Mount Hampton',
  'Mount Hardey',
  'Mount Hardman',
  'Mount Hawthorn',
  'Mount Helena',
  'Mount Hill',
  'Mount Holland',
  'Mount Horner',
  'Mount Jackson',
  'Mount Lawley',
  'Mount Lindesay',
  'Mount Madden',
  'Mount Magnet',
  'Mount Melville',
  'Mount Nasura',
  'Mount Ney',
  'Mount Observation',
  'Mount Palmer',
  'Mount Pleasant',
  'Mount Richon',
  'Mount Romance',
  'Mount Sheila',
  'Mount Sheridan',
  'Mount Stirling',
  'Mount Tarcoola',
  'Mount Walker',
  'Mount Wells',
  'Mouroubra',
  'Muchea',
  'Muckenburra',
  'Mueller Ranges',
  'Muja',
  'Mukinbudin',
  'Mulataga',
  'Mulga Downs',
  'Mullaloo',
  'Mullalyup',
  'Mullewa',
  'Mullingar',
  'Muluckine',
  'Mumballup',
  'Mumberkine',
  'Mundabullangana',
  'Mundaring',
  'Mundijong',
  'Mundrabilla',
  'Mungalup',
  'Munglinup',
  'Munster',
  'Muntadgin',
  'Muradup',
  'Murchison',
  'Murdoch',
  'Murdong',
  'Muresk',
  'Myalup',
  'Myara',
  'Myaree',
  'Myrup',
  'Nabawa',
  'Nairibin',
  'Nalkain',
  'Nalyerlup',
  'Namban',
  'Nambeelup',
  'Nambung',
  'Nanarup',
  'Nanga',
  'Nanga Brook',
  'Nangeenan',
  'Nangetty',
  'Nannup',
  'Nanson',
  'Nanutarra',
  'Napier',
  'Naraling',
  'Narembeen',
  'Narngulu',
  'Narra Tarra',
  'Narrakine',
  'Narraloggan',
  'Narrikup',
  'Narrogin',
  'Narrogin Valley',
  'Naturaliste',
  'Naval Base',
  'Neale',
  'Nedlands',
  'Needilup',
  'Neendaling',
  'Neerabup',
  'Neergabby',
  'Nembudding',
  'Neridup',
  'Nerramyne',
  'Nerren Nerren',
  'New Norcia',
  'Newcarlbeon',
  'Newdegate',
  'Newlands',
  'Newman',
  'Ngaanyatjarra-Giles',
  'Nickol',
  'Nilgen',
  'Nillup',
  'Ningaloo',
  'Nippering',
  'Nirimba',
  'Noggerup',
  'Nokaning',
  'Nolba',
  'Nollamara',
  'Nomans Lake',
  'Noranda',
  'Nornalup',
  'Norpa',
  'Norseman',
  'North Baandee',
  'North Bannister',
  'North Beach',
  'North Bodallin',
  'North Boyanup',
  'North Burngup',
  'North Cascade',
  'North Coogee',
  'North Dandalup',
  'North Eradu',
  'North Fremantle',
  'North Greenbushes',
  'North Jindong',
  'North Kellerberrin',
  'North Kukerin',
  'North Kununoppin',
  'North Lake',
  'North Lake Grace',
  'North Moulyinning',
  'North Perth',
  'North Plantations',
  'North Stirlings',
  'North Tammin',
  'North Trayning',
  'North Walpole',
  'North West Cape',
  'North Wialki',
  'North Yelbeni',
  'North Yunderup',
  'Northam',
  'Northampton',
  'Northbridge',
  'Northcliffe',
  'Northern Gully',
  'Northlands',
  'Nowergup',
  'Nugadong',
  'Nukarni',
  'Nullagine',
  'Nullaki',
  'Nulsen',
  'Nungarin',
  'Nunierra',
  'Nunile',
  'Nyabing',
  'Nyamup',
  "O'Connor",
  'Oakajee',
  'Oakford',
  'Oakley',
  'Ocean Beach',
  'Ocean Reef',
  'Ogilvie',
  'Old Plains',
  'Oldbury',
  'Ongerup',
  'Onslow',
  'Oombulgurri',
  'Ora Banda',
  'Orana',
  'Orange Grove',
  'Orange Springs',
  'Orchid Valley',
  'Ord River',
  'Orelia',
  'Osborne Park',
  'Osmington',
  'Padbury',
  'Palgarup',
  'Pallinup',
  'Palmdale',
  'Palmer',
  'Palmyra',
  'Pannawonica',
  'Pantapin',
  'Paraburdoo',
  'Paradise',
  'Pardoo',
  'Parker Range',
  'Parkerville',
  'Parkeston',
  'Parkfield',
  'Parklands',
  'Parkwood',
  'Parmelia',
  'Parryville',
  'Patjarr',
  'Paulls Valley',
  'Paynedale',
  'Paynes Find',
  'Paynesville',
  'Peaceful Bay',
  'Peak Hill',
  'Pearsall',
  'Peedamulla',
  'Peerabeelup',
  'Pegs Creek',
  'Pemberton',
  'Peppermint Grove',
  'Peppermint Grove Beach',
  'Perenjori',
  'Perillup',
  'Peron',
  'Perth',
  'Perth Adelaide Tce',
  'Perth Airport',
  'Perth East St Georges Tce',
  'Perth St Georges Tce',
  'Perup',
  'Petrudor',
  'Piara Waters',
  'Piawaning',
  'Piccadilly',
  'Pickering Brook',
  'Picton',
  'Picton East',
  'Piesse Brook',
  'Piesseville',
  'Pindar',
  'Pingaring',
  'Pingelly',
  'Pingrup',
  'Pinjar',
  'Pinjarra',
  'Pink Lake',
  'Pintharuka',
  'Pinwernying',
  'Pippingarra',
  'Pithara',
  'Plumridge Lakes',
  'Preston Beach',
  'Preston Settlement',
  'Prevelly',
  'Prince Regent River',
  'Pumphreys Bridge',
  'Purnululu',
  'Quairading',
  'Qualeup',
  'Quedjinup',
  'Queens Park',
  'Queenwood',
  'Quelagetting',
  'Quellington',
  'Quindalup',
  'Quindanning',
  'Quinninup',
  'Quinns Rocks',
  'Ranford',
  'Rangeway',
  'Ravensthorpe',
  'Ravenswood',
  'Rawlinna',
  'Red Gully',
  'Red Hill',
  'Redbank',
  'Redcliffe',
  'Redgate',
  'Redmond',
  'Redmond West',
  'Reedy',
  'Regans Ford',
  'Reinscourt',
  'Remlap',
  'Reservoir',
  'Ridgewood',
  'Ringbark',
  'Riverton',
  'Rivervale',
  'Robinson',
  'Rockingham',
  'Rockingham Beach',
  'Rocklea',
  'Rockwell',
  'Rocky Gully',
  'Roebourne',
  'Roebuck',
  'Roelands',
  'Roleystone',
  'Rosa Brook',
  'Rosa Glen',
  'Rossmore',
  'Rossmoyne',
  'Rothsay',
  'Rottnest Island',
  'Royal Australian Navy Warships',
  'Ruabon',
  'Rudds Gully',
  'Ryansbrook',
  'Sabina River',
  'Safety Bay',
  'Salmon Gums',
  'Samson',
  'San Remo',
  'Sandpatch',
  'Sandsprings',
  'Sandstone',
  'Sandy Gully',
  'Sawyers Valley',
  'Scaddan',
  'Scarborough',
  'Schroeder',
  'Scotsdale',
  'Scott River',
  'Scott River East',
  'Scotts Brook',
  'Seabird',
  'Secret Harbour',
  'Seppings',
  'Serpentine',
  'Seville Grove',
  'Shackleton',
  'Shadforth',
  'Shannon',
  'Shannon River Mill',
  'Shark Bay',
  'Shay Gap',
  'Shelley',
  'Shenton Park',
  'Sherlock',
  'Shoalwater',
  'Shotts',
  'Siesta Park',
  'Silver Sands',
  'Sinagra',
  'Sinclair',
  'Singleton',
  'Sir Samuel',
  'Skeleton Rock',
  'Smith Brook',
  'Solus',
  'Somerville',
  'Sorrento',
  'South Bodallin',
  'South Boulder',
  'South Bunbury',
  'South Burracoppin',
  'South Carnarvon',
  'South Datatine',
  'South Doodlakine',
  'South Fremantle',
  'South Glencoe',
  'South Greenough',
  'South Guildford',
  'South Hedland',
  'South Kalgoorlie',
  'South Kukerin',
  'South Kumminin',
  'South Kununoppin',
  'South Lake',
  'South Lake Grace',
  'South Murchison',
  'South Newdegate',
  'South Perth',
  'South Plantations',
  'South Quairading',
  'South Stirling',
  'South Tammin',
  'South Trayning',
  'South Yelbeni',
  'South Yilgarn',
  'South Yuna',
  'South Yunderup',
  'Southampton',
  'Southern Brook',
  'Southern Cross',
  'Southern River',
  'Spalding',
  'Spearwood',
  'Spencer Park',
  'Spencers Brook',
  'Springfield',
  'Springs',
  'St George Ranges',
  'St James',
  'St Ronans',
  'Stake Hill',
  'Stirling',
  'Stirling Estate',
  'Stirling Range National Park',
  'Stoneville',
  'Stove Hill',
  'Strathalbyn',
  'Stratham',
  'Stratherne',
  'Stratton',
  'Strelley',
  'Sturt Creek',
  'Subiaco',
  'Subiaco East',
  'Success',
  'Sunnyside',
  'Sunset Beach',
  'Swan View',
  'Swanbourne',
  'Takalarup',
  'Talandji',
  'Talbot',
  'Talbot West',
  'Talgomine',
  'Talisker',
  'Tamala',
  'Tamala Park',
  'Tambellup',
  'Tammin',
  'Tampu',
  'Tanami',
  'Tandegin',
  'Tapping',
  'Tarcoola Beach',
  'Tardun',
  'Tarin Rock',
  'Tarwonga',
  'Teesdale',
  'Telfer',
  'Tenindewa',
  'Tenterden',
  'The Lakes',
  'The Plains',
  'The Spectacles',
  'The Vines',
  'Thevenard Island',
  'Thomson Brook',
  'Thornlie',
  'Three Springs',
  'Throssell',
  'Tibradden',
  'Tincurrin',
  'Tingledale',
  'Tjirrkarli',
  'Tjukurla',
  'Tom Price',
  'Tonebridge',
  'Toodyay',
  'Toolibin',
  'Toolonga',
  'Toompup',
  'Torbay',
  'Torndirrup',
  'Townsendale',
  'Trafalgar',
  'Trayning',
  'Treeton',
  'Trent',
  'Trigg',
  'Trigwell',
  'Tuart Hill',
  'Turkey Hill',
  'Tutunup',
  'Two Rocks',
  'Ucarty',
  'Ucarty West',
  'Uduc',
  'Ularring',
  'Upper Capel',
  'Upper Murray',
  'Upper Swan',
  'Upper Warren',
  'Useless Loop',
  'Usher',
  'Utakarra',
  'Valentine',
  'Vancouver Peninsula',
  'Varley',
  'Vasse',
  'Victoria Park',
  'Victoria Rock',
  'Victory Heights',
  'Vittoria',
  'Viveash',
  'Wadderin',
  'Waddington',
  'Waddy Forest',
  'Waeel',
  'Wagerup',
  'Waggrakine',
  'Wagin',
  'Waikiki',
  'Walebing',
  'Walgoolan',
  'Walkaway',
  'Wallareenya',
  'Wallaroo',
  'Walliston',
  'Walmsley',
  'Walpole',
  'Walsall',
  'Walyormouring',
  'Walyunga National Park',
  'Walyurin',
  'Wamenusking',
  'Wandana',
  'Wandering',
  'Wandi',
  'Wandillup',
  'Wandina',
  'Wanerie',
  'Wangara',
  'Wannamal',
  'Wannan',
  'Wannanup',
  'Wanneroo',
  'Wansbrough',
  'Warawarrup',
  'Warburton',
  'Wardering',
  'Warding East',
  'Warmun',
  'Warnbro',
  'Warner Glen',
  'Waroona',
  'Warrachuppin',
  'Warradarge',
  'Warralakin',
  'Warrenup',
  'Warwick',
  'Waterbank',
  'Watercarrin',
  'Waterford',
  'Waterloo',
  'Watermans Bay',
  'Watheroo',
  'Wattening',
  'Wattle Grove',
  'Wattleup',
  'Wattoning',
  'Webberton',
  'Wedge Island',
  'Wedgecarrup',
  'Wedgefield',
  'Welbungin',
  'Weld Range',
  'Wellard',
  'Wellesley',
  'Wellington Forest',
  'Wellington Mill',
  'Wellstead',
  'Welshpool',
  'Wembley',
  'Wembley Downs',
  'West Ballidu',
  'West Beach',
  'West Binnu',
  'West Busselton',
  'West Cape Howe',
  'West Casuarinas',
  'West Coolup',
  'West End',
  'West Fitzgerald',
  'West Holleton',
  'West Island Cocos (Keeling) Islands',
  'West Kalgoorlie',
  'West Lamington',
  'West Leederville',
  'West Lyons River',
  'West Perth',
  'West Pingelly',
  'West Pinjarra',
  'West River',
  'West Swan',
  'West Toodyay',
  'Westdale',
  'Westfield',
  'Westminster',
  'Westonia',
  'Westwood',
  'Whim Creek',
  'Whitby',
  'White Gum Valley',
  'White Peak',
  'Whiteman',
  'Whittaker',
  'Wialki',
  'Wicherina',
  'Wicherina South',
  'Wickepin',
  'Wickham',
  'Widgiemooltha',
  'Wilberforce',
  'Wilbinga',
  'Wilga',
  'Wilga West',
  'Wilgarrup',
  'Wilgoyne',
  'Willagee',
  'Willagee Central',
  'Willare',
  'Willetton',
  'William Bay',
  'Williams',
  'Williamstown',
  'Willyung',
  'Wilson',
  'Wiluna',
  'Wilyabrup',
  'Winchester',
  'Windabout',
  'Windy Harbour',
  'Wingellina',
  'Winnejup',
  'Winthrop',
  'Witchcliffe',
  'Withers',
  'Wittenoom',
  'Wittenoom Hills',
  'Wogolin',
  'Wokalup',
  'Womarden',
  'Wongamine',
  'Wongan Hills',
  'Wongoondy',
  'Wonnerup',
  'Wonthella',
  'Woodanilling',
  'Woodbridge',
  'Woodlands',
  'Woodleigh',
  'Woodridge',
  'Woodvale',
  'Woogenellup',
  'Woolgorong',
  'Woolocutty',
  'Wooramel',
  'Wooroloo',
  'Woorree',
  'Woottating',
  'Worsley',
  'Wubin',
  'Wundowie',
  'Wungong',
  'Wuraming',
  'Wyalkatchem',
  'Wyening',
  'Wyndham',
  'Wyola',
  'Wyola West',
  'Xantippe',
  'Yabberup',
  'Yakamia',
  'Yalardy',
  'Yalgoo',
  'Yallabatharra',
  'Yallingup',
  'Yallingup Siding',
  'Yalyalup',
  'Yanchep',
  'Yandanooka',
  'Yandoo Creek',
  'Yangebup',
  'Yanmah',
  'Yannarie',
  'Yarawindah',
  'Yardarino',
  'Yarding',
  'Yarloop',
  'Yarragadee',
  'Yathroo',
  'Yeagarup',
  'Yeal',
  'Yealering',
  'Yelbeni',
  'Yellowdine',
  'Yelverton',
  'Yerecoin',
  'Yetna',
  'Yilkari',
  'Yilliminning',
  'Yoganup',
  'Yokine',
  'Yokine South',
  'Yoongarillup',
  'York',
  'Yorkrakine',
  'Yornaning',
  'Yornup',
  'Yoting',
  'Youndegin',
  'Youngs Siding',
  'Yourdamung Lake',
  'Yuna',
  'Zanthus',
  'Zuytdorp',
];

export default suburbs;
