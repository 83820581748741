import {
  Box,
  Button,
  Container,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import * as React from 'react';

const App = () => (
  <Container py={{ base: '16', md: '24' }}>
    <Box
      bg="bg-accent"
      color="on-accent"
      borderRadius="xl"
      px={{ base: '6', lg: '16' }}
      py={{ base: '10', lg: '16' }}
    >
      <Stack
        spacing="8"
        direction={{ base: 'column', lg: 'row' }}
        justify="space-between"
        align={'center'}
      >
        <Stack spacing="4" maxW="2xl">
          <Heading size="sm">Ready to have your carpet repaired?</Heading>
          <Text
            color="on-accent-muted"
            fontSize={useBreakpointValue({ base: 'lg', lg: 'xl' })}
          >
            Feel free to check out any of the pages on our site or click on the
            buttons see get an online quote or see the services we provide.
          </Text>
        </Stack>
        <Stack
          spacing="3"
          direction={{ base: 'column', sm: 'row' }}
          h="full"
          justify={'center'}
        >
          <Button
            variant="secondary-on-accent"
            size="lg"
            as="a"
            href="/services"
          >
            See services
          </Button>
          <Button variant="primary-on-accent" size="lg" as="a" href="/quote">
            Get a quote online
          </Button>
        </Stack>
      </Stack>
    </Box>
  </Container>
);
export default App;
