import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  IconButton,
  useBreakpointValue,
  useColorModeValue,
  Text,
  Divider,
  Link,
} from '@chakra-ui/react';
import * as React from 'react';
import { FiMenu } from 'react-icons/fi';

const Navbar = ({ page }) => {
  const isDesktop = useBreakpointValue({ base: false, md: true });
  // create a function to make strings lowercase and remove spaces
  const makeLowercase = str => {
    return str.toLowerCase().replace(/\s/g, '');
  };

  return (
    <>
      <Box as="section">
        <Box
          as="nav"
          bg="bg-surface"
          boxShadow={useColorModeValue('sm', 'sm-dark')}
        >
          <Container py={{ base: '4', lg: '5' }}>
            <HStack spacing="10" justify="space-between">
              <Text fontSize="xl" fontWeight={'bold'}>
                The Perth Carpet Repair Specialist
              </Text>
              {isDesktop ? (
                <Flex justify="space-between" flex="1">
                  <Flex
                    justify="space-between"
                    alignItems={'center'}
                    flex="1"
                    mx={6}
                  >
                    {[
                      'Home',
                      'About',
                      'Services',
                      'Testimonials',
                      'Gallery',
                      'FAQ',
                    ].map(item => (
                      <Link
                        key={item}
                        href={`${makeLowercase(item)}`}
                        _active
                        color={page === item && 'accent'}
                      >
                        {item}
                      </Link>
                    ))}

                    <Button variant="primary" as="a" href="/quote">
                      Get a quote online
                    </Button>
                  </Flex>
                </Flex>
              ) : (
                <IconButton
                  variant="ghost"
                  icon={<FiMenu fontSize="1.25rem" />}
                  aria-label="Open Menu"
                />
              )}
            </HStack>
          </Container>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export { Navbar };
