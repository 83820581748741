import {
  Box,
  ButtonGroup,
  Container,
  Divider,
  IconButton,
  Stack,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';
import { FaFacebook, FaEnvelope, FaPhone } from 'react-icons/fa';

const App = () => (
  <Box bg="bg-accent" color="on-acccent">
    <Container as="footer" role="contentinfo">
      <Stack
        justify="space-between"
        align="end"
        direction={{
          base: 'column',
          lg: 'row',
        }}
        py={{
          base: '12',
          md: '16',
        }}
        spacing="8"
      >
        <Stack
          spacing={{
            base: '6',
            md: '8',
          }}
          align="start"
        >
          <Text fontSize="xl" fontWeight={'bold'} color="white">
            The Perth Carpet Repair Specialist
          </Text>
          <Text color="on-accent-muted" maxW={'md'}>
            We repair carpets to the highest standard at the fraction of the
            price of a new carpet installation.
          </Text>
        </Stack>
        <Stack spacing={8}>
          <Stack
            direction={'row'}
            color="on-accent-muted"
            alignItems={'center'}
          >
            <FaEnvelope fontSize="2rem" />
            <Box w={1} />
            <Text size={'xl'}>markpritchard913@gmail.com</Text>
          </Stack>
          <Stack
            direction={'row'}
            color="on-accent-muted"
            alignItems={'center'}
          >
            <FaPhone fontSize="2rem" />
            <Box w={1} />
            <Text size={'xl'}>0403 722 809</Text>
          </Stack>
        </Stack>
      </Stack>

      <Divider borderColor="bg-accent-subtle" />
      <Stack
        pt="8"
        pb="12"
        justify="space-between"
        direction={{
          base: 'column-reverse',
          md: 'row',
        }}
        align="center"
      >
        <Text fontSize="sm" color="on-accent-subtle">
          &copy; {new Date().getFullYear()} The Perth Carpet Repair Specialist.
          All rights reserved.
        </Text>
        <ButtonGroup variant="ghost-on-accent">
          <IconButton
            as="a"
            href="https://www.facebook.com/profile.php?id=100066392755465"
            aria-label="Facebook"
            target="_blank"
            icon={<FaFacebook fontSize="1.25rem" />}
          />
        </ButtonGroup>
      </Stack>
    </Container>
  </Box>
);

export default App;

/*const links = [
  {
    title: 'Links',
    links: [
      {
        label: 'Home',
        href: '#',
      },
      {
        label: 'About',
        href: '#',
      },
      {
        label: 'Services',
        href: '#',
      },
      {
        label: 'Testimonials',
        href: '#',
      },
    ],
  },
  {
    title: 'Forms',
    links: [
      {
        label: 'Contact us',
        href: '#',
      },
      {
        label: 'Get a quote online',
        href: '#',
      },
    ],
  },
  {
    title: 'Social media',
    links: [
      {
        label: 'Facebook',
        href: '#',
      },
    ],
  },
];
<SimpleGrid
          columns={{
            base: 1,
            md: 3,
          }}
          gap="8"
          width={{
            base: 'full',
            lg: 'auto',
          }}
        >
          {links.map((group, idx) => (
            <Stack
              key={idx}
              spacing="4"
              minW={{
                lg: '40',
              }}
            >
              <Text
                fontSize="sm"
                fontWeight="semibold"
                color="on-accent-subtle"
              >
                {group.title}
              </Text>
              <Stack spacing="3" shouldWrapChildren>
                {group.links.map((link, idx) => (
                  <Link key={idx} as="a" color={'on-accent'} href={link.href}>
                    {link.label}
                  </Link>
                ))}
              </Stack>
            </Stack>
          ))}
        </SimpleGrid>
*/
