import React, { useState } from 'react';
import {
  Box,
  Heading,
  Button,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Textarea,
  Radio,
  RadioGroup,
  Alert,
  AlertIcon,
  HStack,
  Select,
  Badge,
  useBreakpointValue,
  SimpleGrid,
  Text,
  Center,
  VStack,
  Square,
  Icon,
  Flex,
} from '@chakra-ui/react';

import { FiUploadCloud } from 'react-icons/fi';
import postcodes from '../postcodes.js';

import PageWrap from '../components/page-wrap';

const Dropzone = props => (
  <Center
    borderWidth="1px"
    borderRadius="lg"
    px="6"
    py="4"
    bg="white"
    {...props}
  >
    <VStack spacing="3">
      <Square size="10" bg="bg-subtle" borderRadius="lg">
        <Icon as={FiUploadCloud} boxSize="5" color="muted" />
      </Square>
      <VStack spacing="1">
        <HStack spacing="1" whiteSpace="nowrap">
          <Button variant="link" colorScheme="blue" size="sm">
            Click to upload
          </Button>
          <Text fontSize="sm" color="muted">
            or drag and drop
          </Text>
        </HStack>
        <Text fontSize="xs" color="muted">
          PNG, JPG or GIF up to 2MB
        </Text>
      </VStack>
    </VStack>
  </Center>
);

function App() {
  const [currentFormSection, setCurrentFormSection] = useState(0);
  return (
    <PageWrap>
      <SimpleGrid bg={'white'} columns={3}>
        <Box></Box>
        <Stack spacing={10} py={16}>
          <Box>
            <Heading
              size={useBreakpointValue({
                sm: 'sm',
                base: 'md',
              })}
              fontWeight="extrabold"
              mb={4}
            >
              Online quote form
            </Heading>
            {currentFormSection === 0 && <ContactQuestions />}
            {currentFormSection === 1 && <CarpetQuestions />}
            {currentFormSection === 2 && <SpareQuestions />}
            {currentFormSection === 3 && <SuccessForm />}
            <Box mt={4} />
            {currentFormSection === 0 && (
              <Button
                size="md"
                colorScheme="blue"
                w={'full'}
                onClick={() => {
                  setCurrentFormSection(1);
                }}
              >
                Continue to next step
              </Button>
            )}
            {currentFormSection === 1 && (
              <HStack py={4} spacing={4}>
                <Button
                  size="md"
                  width={'50%'}
                  onClick={() => {
                    setCurrentFormSection(0);
                  }}
                >
                  {`  Go back  `}
                </Button>
                <Button
                  size="md"
                  colorScheme="blue"
                  w={'full'}
                  onClick={() => {
                    setCurrentFormSection(2);
                  }}
                >
                  Continue to next step
                </Button>
              </HStack>
            )}
            {currentFormSection === 2 && (
              <Stack pt={4}>
                <Button
                  size="md"
                  colorScheme="blue"
                  w={'full'}
                  onClick={() => {
                    setCurrentFormSection(3);
                  }}
                >
                  Submit form
                </Button>
              </Stack>
            )}
          </Box>
        </Stack>
      </SimpleGrid>
    </PageWrap>
  );
}

const SuccessForm = () => {
  return (
    <Flex
      bg={'gray.100'}
      rounded="xl"
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Stack
        spacing={4}
        alignItems={'center'}
        justifyContent={'center'}
        p={16}
        textAlign="center"
      >
        <Heading
          size={useBreakpointValue({
            sm: 'sm',
            base: 'md',
          })}
          fontWeight="extrabold"
          mb={4}
        >
          Form submitted successfully!
        </Heading>
        <Text>
          Thank you for your submission. We will be in touch with you shortly.
        </Text>
      </Stack>
    </Flex>
  );
};

const SpareQuestions = () => {
  return (
    <Box>
      <Stack spacing={6}>
        <Stack spacing={6}>
          <Text size={'lg'} fontWeight="extrabold">
            Step 3/3 - Repair details
          </Text>
          <FormControl>
            <FormLabel mb={4}>
              Do you have spare carpet? <Badge>Needed for repair</Badge>
            </FormLabel>

            <RadioGroup disabled mb={4} value={'yes'}>
              <Stack direction="row">
                <Radio value={'yes'}>Yes</Radio>
                <Radio value={'no'}>No</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel mb={4}>
              Do you have skirting boards in your carpeted room?
            </FormLabel>
            <RadioGroup mb={4} disabled value={'yes'}>
              <Stack direction="row">
                <Radio value={'yes'}>Yes</Radio>
                <Radio value={'no'}>No</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>

          <Stack
            spacing={6}
            onClick={e => {
              return e.preventDefault;
            }}
          >
            <FormControl>
              <FormLabel mb={4}>
                Have the skirting boards been fitted on top of your carpets
                after the original install?
              </FormLabel>
              <RadioGroup mb={4} disabled value={'yes'}>
                <Stack direction="row">
                  <Radio value={'yes'}>Yes</Radio>
                  <Radio value={'no'}>No</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
            <Box
              onClick={e => {
                return e.preventDefault;
              }}
            >
              <FormControl>
                <FormLabel mb={4}>
                  Do the skirting boards grip the carpet tighly?
                </FormLabel>
                <RadioGroup mb={4} disabled value={'yes'}>
                  <Stack direction="row">
                    <Radio value={'yes'}>Yes</Radio>
                    <Radio value={'no'}>No</Radio>
                  </Stack>
                </RadioGroup>
                <Alert status="info" mt={4} colorScheme={'gray'}>
                  <AlertIcon />A great way to check is to pull a corner of the
                  carpet up with pliers to see how gripped the carpet is
                </Alert>
              </FormControl>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

const ContactQuestions = () => {
  return (
    <Box>
      <Stack spacing={4}>
        <Text size={'lg'} fontWeight="extrabold">
          Step 1/3 - Contact details
        </Text>
        <FormControl>
          <FormLabel>Name</FormLabel>
          <Input type="text" value="Jonathon Doe" disabled />
          <FormErrorMessage></FormErrorMessage>
        </FormControl>
        <HStack spacing={4}>
          <FormControl flexGrow={1}>
            <FormLabel>Suburb</FormLabel>
            <Select placeholder="Select option" value={'Cannington'} disabled>
              {postcodes.map(postcode => (
                <option key={postcode} value={postcode}>
                  {postcode}
                </option>
              ))}
            </Select>

            <FormErrorMessage></FormErrorMessage>
          </FormControl>
        </HStack>
        <FormControl>
          <RadioGroup mb={4} disabled value="sms">
            <Stack direction="row" spacing={4}>
              <Radio value={'email'}>Email</Radio>
              <Radio value={'phone'}>Phone</Radio>
              <Radio value={'sms'}>SMS</Radio>
            </Stack>
          </RadioGroup>
        </FormControl>
        <FormControl>
          <FormLabel>Phone number</FormLabel>
          <Input type="text" value="+61432101234" disabled />
          <FormErrorMessage></FormErrorMessage>
        </FormControl>
      </Stack>
    </Box>
  );
};

const CarpetQuestions = () => {
  return (
    <Stack spacing={6}>
      <Stack spacing={6}>
        <Text size={'lg'} fontWeight="extrabold">
          Step 2/3 - Problem details
        </Text>
        <FormControl>
          <FormLabel>Please describe your carpet issues:</FormLabel>
          <Textarea
            value={`There are several large stains that won't come out no matter how much I scrub. Secondly, the carpet is starting to fray and come up at the seams in several places. Finally, there are a few areas where the carpet is completely worn through.`}
            type="text"
            noOfLines={4}
            disabled
            placeholder="Please enter as much detail about the problems with your carpet as possible"
          />
          <FormErrorMessage></FormErrorMessage>
        </FormControl>

        <FormControl id="file">
          <FormLabel>Dropzone</FormLabel>
          <Dropzone />
        </FormControl>
      </Stack>
    </Stack>
  );
};

export default App;

/*            <Stack spacing={4}></Stack>

 <FormControl>
                <FormLabel mb={4}>Do you have spare carpet?</FormLabel>
                <Tabs isFitted variant={'enclosed-colored'}>
                  <TabList>
                    <Tab>Yes</Tab>
                    <Tab>No</Tab>
                  </TabList>

                  <TabPanels>
                    <TabPanel border="1px" borderColor="gray.200">
                      <Text whiteSpace={'pre-wrap'}>
                        {`Great news! Having spare carpet means I can easily make the repair without you needing to go and purchase more. \n\nI'll be able to get the repair done quickly and efficiently.`}
                      </Text>
                    </TabPanel>
                    <TabPanel border="1px" borderColor="gray.200">
                      <Text whiteSpace={'pre-wrap'}>
                        {`It's likely I'll need spare carpet to use for your repair. If you don't have any, I'd suggest using a section of carpet from a wardrobe. \n\nIf you're renting, you can go to your nearest carpet retailer to see if they can buy the same or similar to go back into the wardrobe. \n\nOnce you've purchased a section of carpet, I'll be able to carry out the repair & install your purchased piece of carpet in the wardrobe.`}
                      </Text>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </FormControl>

              <FormControl>
                <FormLabel mb={4}>
                  Were skirting boards installed DIY or on top of the carpet?
                </FormLabel>
                <Tabs isFitted variant={'enclosed-colored'}>
                  <TabList>
                    <Tab>Yes</Tab>
                    <Tab>No</Tab>
                  </TabList>

                  <TabPanels>
                    <TabPanel border="1px" borderColor="gray.200">
                      <Text whiteSpace={'pre-wrap'}>
                        {`If the skirtings only barely touch the carpets this isn't a problem. \n\nIf they're clamped down tight on top of the carpets then they must taken off prior to my arrival. The easiest way to check is to pull a corner of the carpet up with pliers to see the position of the grippers underneath & skirtings.`}
                      </Text>
                    </TabPanel>
                    <TabPanel border="1px" borderColor="gray.200">
                      <Text whiteSpace={'pre-wrap'}>
                        {`It's likely I'll need spare carpet to use for your repair. If you don't have any, I'd suggest using a section of carpet from a wardrobe. \n\nIf you're renting, you can go to your nearest carpet retailer to see if they can buy the same or similar to go back into the wardrobe. \n\nOnce you've purchased a section of carpet, I'll be able to carry out the repair & install your purchased piece of carpet in the wardrobe.`}
                      </Text>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </FormControl>
              <Tabs isFitted variant={'enclosed-colored'}>
                  <TabList>
                    <Tab>Yes</Tab>
                    <Tab>No</Tab>
                  </TabList>

                  <TabPanels>
                    <TabPanel border="1px" borderColor="gray.200">
                      <Text whiteSpace={'pre-wrap'}>
                        {`If the skirtings only barely touch the carpets this isn't a problem. \n\nIf they're clamped down tight on top of the carpets then they must taken off prior to my arrival. The easiest way to check is to pull a corner of the carpet up with pliers to see the position of the grippers underneath & skirtings.`}
                      </Text>
                    </TabPanel>
                    <TabPanel border="1px" borderColor="gray.200">
                      <Text whiteSpace={'pre-wrap'}>
                        {`It's likely I'll need spare carpet to use for your repair. If you don't have any, I'd suggest using a section of carpet from a wardrobe. \n\nIf you're renting, you can go to your nearest carpet retailer to see if they can buy the same or similar to go back into the wardrobe. \n\nOnce you've purchased a section of carpet, I'll be able to carry out the repair & install your purchased piece of carpet in the wardrobe.`}
                      </Text>
                    </TabPanel>
                  </TabPanels>
                </Tabs>

                {hasSpareCarpet === 'yes' && (
                  <Alert status="success">
                    <AlertIcon />
                    Great, I can use your spare carpet for the repair.
                  </Alert>
                )}

                {hasSpareCarpet === 'no' && (
                  <Box bg={'gray.50'} p={4}>
                    <Text whiteSpace={'pre-wrap'}>
                      {`Not a problem!\n\nIf you don't have any/enough, I'd suggest using a section of carpet that isn't often seen such as carpet from a wardrobe.\n\nIf you'd to keep carpet in the wardrobe, I'd suggest going to your nearest carpet retailer to see if they have the same or similiar carpet. If you purchase the carpet, I can install it in your wardrobe after the repair has been completed.`}
                    </Text>
                  </Box>
                )}
 */
