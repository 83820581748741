import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import { ChakraProvider } from '@chakra-ui/react';
import { theme } from '@chakra-ui/pro-theme';

import Home from './pages/home';
import Testimonials from './pages/testimonials';
import About from './pages/about';
import Quote from './pages/quote';
import Faq from './pages/faq';
import Services from './pages/services';
import Gallery from './pages/gallery';

import NotFound from './pages/not-found';

import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/quote" element={<Quote />} />
      <Route path="/services" element={<Services />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/testimonials" element={<Testimonials />} />
      <Route path="*" element={<NotFound />} />
    </>
  )
);

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <StrictMode>
    <ChakraProvider theme={theme}>
      <RouterProvider router={router} />
    </ChakraProvider>
  </StrictMode>
);
