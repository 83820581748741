import * as React from 'react';
import {
  Box,
  Text,
  SimpleGrid,
  Heading,
  Stack,
  Container,
  useBreakpointValue,
  Link,
} from '@chakra-ui/react';
import { Quote } from '../components/quote';
import PageWrap from '../components/page-wrap';

const quotes = [
  {
    review: `Highly recommend! When other carpet repair companies were asked if they could repair our
    ripped carpet they all said it couldn't be done and
        that it would be best to replace the whole 2m x 4m
        bedroom! The Carpet Repair Specialist did a
        miracle work and blended in our carpet seamlessly.
        Reasonably priced, couldn't be more happy.`,
    name: 'Sherry Lee',
  },
  {
    review: `Super, super happy with our carpet restretch
  today. Mark was punctual, easy to contact,
  professional and very knowledgeable regarding the
  restretch and cause of the issue. I highly
  recommend The Carpet Repair Specialist and
  wouldn't hesitate to book his services again.`,
    name: 'Michelle Martin',
  },
  {
    review: `Highly recommend. From first contact,
    they were informative and friendly. Professional, they
    patched our carpet in no time and did a beautiful
    job. Wouldn't hesitate to use The Carpet Repair
    Specialist in the future.`,
    name: 'Gina Carlucci',
  },
  {
    review: `Our carpet was only 3 days old when our large dog
    rolled on the floor and caught our loop carpet in
    her collar. I was devastated with the pull in our
    carpet. I now can't even tell where the pull was you
    did an amazing job thankyou so much. I am sure I
    will need you again at some stage. Your works
    amazing thanks heaps Mark`,
    name: 'Darren Byrne',
  },
  {
    review: `I had 2 burns in my carpet which Mark repaired, I could not see the repair or where he took carpet from in the wardrobe. I was very impressed with his work and would highly recommend him for carpet repair work.  Thank you Mark`,
    name: 'Clare Louise',
  },
  {
    review: `I cannot thank Mark enough for the awesome job he did....and so rapid to respond. I wouldn't hesitate to have him repair my carpet again. BRILLIANT`,
    name: 'Julie Farrier',
  },
  {
    review: `A brilliant job, thank you very impressed! On time efficient professional.
    Highly recommended`,
    name: 'Caroline Langham',
  },
  {
    review: `Thank you Mark for the awesome work, I can highly recommend your services`,
    name: 'Toni Boreham',
  },
];

const App = () => {
  // create a function to sort the quotes by review length
  const sortQuotes = (a, b) => {
    // if the length of the review is the same, sort by name
    if (a.review.length === b.review.length) {
      return a.name.localeCompare(b.name);
    }
    // sort by review length
    return b.review.length - a.review.length;
  };
  // sort the quotes
  const sortedQuotes = quotes.sort(sortQuotes);

  return (
    <PageWrap page="Testimonials">
      <Box as="section" bg="bg-surface">
        <Container py={16}>
          <Stack spacing={{ base: '4', md: '5' }}>
            <Heading
              size={useBreakpointValue({
                sm: 'sm',
                base: 'md',
              })}
              fontWeight="extrabold"
            >
              Our testimonials
            </Heading>
            <Text color="muted" fontSize="xl" maxW={'xl'}>
              Here are all of the reviews from our <Link>Facebook page</Link>{' '}
              where we are proud to have a 5 star rating.
            </Text>
          </Stack>
          <SimpleGrid
            columns={useBreakpointValue({ base: 1, md: 2 })}
            py={6}
            spacing={8}
          >
            {sortedQuotes.map(quote => (
              <Quote review={quote.review} name={quote.name} />
            ))}
          </SimpleGrid>
        </Container>
      </Box>
    </PageWrap>
  );
};

export default App;
