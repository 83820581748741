import PageWrap from '../components/page-wrap';
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import * as React from 'react';

const features = [
  {
    name: 'Power stretching your carpets back to industry standards',
  },
  {
    name: 'Retufting of pulled loops & damaged yarn',
  },
  {
    name: 'Seam repair',
  },
  {
    name: 'Patch repair',
  },
];

const App = () => (
  <PageWrap page={'Services'}>
    <Box as="section" bg="bg-surface">
      <Container py={{ base: '16', md: '24' }}>
        <Stack spacing={{ base: '12', md: '16' }}>
          <Stack
            spacing={{ base: '4', md: '5' }}
            align="center"
            textAlign="center"
          >
            <Stack spacing="3">
              <Heading size={useBreakpointValue({ base: 'sm', md: 'md' })}>
                Professional carpet repair services
              </Heading>
            </Stack>
            <Text color="muted" fontSize={{ base: 'lg', md: 'xl' }} maxW="3xl">
              We offer several professional carpet repair services that helps
              damaged carpets look brand new again.
            </Text>
          </Stack>
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            columnGap={8}
            rowGap={{ base: 10, md: 16 }}
          >
            {features.map(feature => (
              <Stack
                key={feature.name}
                spacing={{ base: '4', md: '5' }}
                align="center"
                textAlign="center"
                maxW={{ base: 'sm', md: 'md' }}
                marginX="auto"
              >
                <Stack spacing={{ base: '1', md: '2' }}>
                  <Heading size={'md'} fontWeight="bold">
                    {feature.name}
                  </Heading>
                </Stack>
              </Stack>
            ))}
          </SimpleGrid>
        </Stack>
      </Container>
    </Box>
  </PageWrap>
);

export default App;
