import { Box, Button, Container, Heading, Stack, Text } from '@chakra-ui/react';
import * as React from 'react';

const App = () => (
  <Box as="section" bg="bg-accent" color="on-accent">
    <Container py={{ base: '16', md: '24' }}>
      <Stack spacing={{ base: '8', md: '10' }}>
        <Stack spacing={{ base: '4', md: '5' }} align="center">
          <Heading size={'md'} fontWeight="bold">
            Ready to have your carpets repaired?
          </Heading>
          <Text
            color="on-accent-muteed"
            maxW="2xl"
            textAlign="center"
            fontSize="xl"
          >
            The Perth Carpet Repair Specialist can have your carpets looking
            brand new in no time!
          </Text>
        </Stack>
        <Stack
          spacing="3"
          direction={{ base: 'column', sm: 'row' }}
          justify="center"
        >
          <Button
            variant="secondary-on-accent"
            size="lg"
            as={'a'}
            href="/about"
          >
            Learn more
          </Button>
          <Button variant="primary-on-accent" size="lg" as={'a'} href="/quote">
            Get a quote online
          </Button>
        </Stack>
      </Stack>
    </Container>
  </Box>
);

export default App;
