import {
  Flex,
  Spacer,
  Image,
  Stack,
  Badge,
  useBreakpointValue,
  Heading,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';

const App = () => (
  <Flex bg={'blue.50'} alignItems="center" rounded="2xl" shadow={'xl'}>
    <Image boxSize="sm" src="mark.png" alt="A selfie of Mark Pritchard" />

    <Stack spacing="4">
      <Badge
        colorScheme="blue"
        alignSelf="start"
        size={useBreakpointValue({
          base: 'md',
          md: 'lg',
        })}
      >
        Meet the specialist
      </Badge>
      <Stack
        spacing={{
          base: '4',
          md: '6',
        }}
        maxW={{
          md: 'xl',
          lg: 'md',
          xl: 'xl',
        }}
      >
        <Heading size={'md'} fontWeight="extrabold">
          Mark Pritchard
        </Heading>
        <Text
          fontSize={{
            base: 'lg',
            md: 'xl',
          }}
          color="muted"
        >
          Mark is one of the only carpet repair specialists in Western Australia
          and the perfect choice for any carpet repair job. He has decades of
          experience in the carpet industry and has skill and knowledge in all
          aspects and areas of carpet repair.
        </Text>
      </Stack>
    </Stack>
    <Spacer />
  </Flex>
);

export default App;
