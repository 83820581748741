import PageWrap from '../components/page-wrap';

import { Box, Heading } from '@chakra-ui/react';
import * as React from 'react';
import { Gallery } from '../sections/gallery';

export const images = [
  {
    id: '01',
    src: 'carpet1.jpg',
    alt: 'carpet',
  },
  {
    id: '02',
    src: 'carpet2.jpg',
    alt: 'carpet',
  },
  {
    id: '03',
    src: 'carpet3.jpg',
    alt: 'carpet',
  },
  {
    id: '04',
    src: 'carpet4.jpg',
    alt: 'carpet',
  },
  {
    id: '05',
    src: 'carpet5.jpg',
    alt: 'carpet',
  },
  {
    id: '06',
    src: 'carpet6.jpg',
    alt: 'carpet',
  },
];

const App = () => (
  <PageWrap page={'Gallery'}>
    <Heading as="h1" size="md" fontWeight={'bold'} textAlign="center" my={4}>
      Gallery
    </Heading>
    <Box
      maxW="3xl"
      mx="auto"
      px={{
        base: '4',
        md: '8',
        lg: '12',
      }}
      py={{
        base: '6',
        md: '8',
        lg: '12',
      }}
    >
      <Gallery images={images} />
    </Box>
  </PageWrap>
);

export default App;
