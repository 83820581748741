import PageWrap from '../components/page-wrap';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  SimpleGrid,
  Heading,
  Text,
  Stack,
} from '@chakra-ui/react';

const App = () => (
  <PageWrap page={'FAQ'}>
    <SimpleGrid columns={3} py={16} bg={'white'}>
      <Box />
      <Stack spacing={8}>
        <Heading fontWeight={'bold'} size={'md'}>
          Frequently asked questions
        </Heading>
        <Heading fontWeight={'bold'} size={'xs'}>
          Why is my carpet bubbling?
        </Heading>
        <Text>
          Before we tumble too far down the rabbit hole of what causes carpet
          bubbling, we need to explore what exactly it is. Carpet bubbling
          happens when an area of wall-to-wall carpeting starts to lift or rise
          over the subfloor. This creates little hills or bubbles in the
          carpeting. And it's easy to think that this process will have no
          drastic impact on your flooring. But truthfully, carpet bubbling can
          wreak havoc on your floors. That's why it's essential to know what
          might be causing your carpet to bubble up.
        </Text>
        <Accordion defaultIndex={[0]} allowMultiple>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Humidity With a Chance of Ripples
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              When it comes to what causes carpet bubbling, humidity throughout
              your home often plays a significant role. This is especially true
              if you live in a four-season climate in the summer, as there is a
              dramatic change in conditions compared to the colder months.
              Regardless, in high-humidity areas, moisture in the air can cause
              your carpets to swell. In minor incidents, carpet swells will
              reduce and lay flat over time. But in more challenging situations,
              a professional restretch using a power stretching system will be
              required to get your carpets back to industry standards.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Heavy Items Are a Drag for Your Carpet Plus Wear & Tear
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Another common reason that carpet bubbles is the frequent dragging
              of heavy furniture (or other items) across the carpet's surface.
              We know; re-positioning your couch or moving a weighty bookshelf
              probably doesn't seem like a big deal. But truthfully, it can
              spell big trouble for carpeted floors—especially when heavy
              objects are being moved across them frequently. When you drag
              heavy pieces across the floor, it causes the carpeting to stretch.
              As a result, the loosened spots create bubbles. Thankfully,
              preventing this sort of issue is also pretty straightforward. The
              simplest precautionary measure here would be to lift your heavy
              items and carry them. If the things you're moving are too heavy to
              be lifted, you can place plywood underneath them and use the added
              leverage to get your items in the ideal areas of your home. Always
              remember your carpet is a textile not a hard flooring system,
              textiles behave like a fabric and guess what? Yes you've guessed
              it they stretch over time, put a new pair of jeans on 20 times and
              see what happens! So it's our professional opinion just for this
              reason alone that a professional restretch will be required at
              least once during your carpets warrantable lifetime.
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Improper Installation Causes Carpets To Buckle
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Of course, one of the most common causes of bubbling carpet is
              improper installation. For wall-to-wall carpeting to be correctly
              installed, it must stretch tightly over the subfloor and be
              secured with gripper rods, tack strips or smoothedge which is
              different terminology for the perimeter fixing depending on what
              part of the world you are from. If a carpet installer doesn't pull
              the carpet tautly enough, it forces the carpet to shake loose and
              bubble. But just like all our other carpet rippling issues,
              improper installation can be fixed. By using a power stretching
              system, we will get your carpets back to industry standards which
              may not have been achieved during the original installation
              process. After that, it's a matter of up keep and knowing when to
              call a professional to help. In any case, carpet bubbling can
              occur for various reasons. But when you know what to expect and
              how to prevent it, you can keep your carpets beautiful.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Stack>
    </SimpleGrid>
  </PageWrap>
);

export default App;
