import {
  Box,
  Button,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import * as React from 'react';
import { Navbar } from '../components/navbar';

const App = () => (
  <>
    <Navbar />
    <Box as="section" p={6} bg="white">
      <Box
        bg="accent"
        color="on-accent"
        rounded={'2xl'}
        py={{
          base: '16',
          md: '24',
        }}
      >
        <Stack
          spacing={{
            base: '8',
            md: '10',
          }}
        >
          <Stack
            spacing={{
              base: '4',
              md: '5',
            }}
            align="center"
          >
            <Heading
              size={useBreakpointValue({
                base: 'sm',
                md: 'md',
              })}
            >
              Error 404
            </Heading>
            <Text
              color="on-accent-muteed"
              maxW="2xl"
              textAlign="center"
              fontSize="xl"
            >
              The page you are looking for does not exist. Nothing here. Please
              check the URL and try again. If you believe this is an error,
              please contact us.
            </Text>
          </Stack>
          <Stack
            spacing="3"
            direction={{
              base: 'column',
              sm: 'row',
            }}
            justify="center"
          >
            <Button variant="secondary-on-accent" size="lg">
              Return home
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Box>
  </>
);

export default App;
