import React from 'react';
import { Stack, Flex, Spacer } from '@chakra-ui/react';
import PageWrap from '../components/page-wrap';

import Meet from '../sections/about/meet';
import Tools from '../sections/about/tools';
import Services from '../sections/about/services';

const App = () => (
  <PageWrap page={'About'}>
    <Stack columns={2} bg="white" py={16} align="center">
      <Flex align={'center'} pb={16}>
        <Spacer />
        <Meet />
        <Spacer />
      </Flex>
      <Tools />
      <Services />
    </Stack>
  </PageWrap>
);

export default App;
